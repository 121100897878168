<template>
  <div id="user-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template v-if="mode === 'NEW'">Create User</template>
        <template v-if="mode === 'EDIT'">Edit User</template>
      </template>

      <template #description>
        <template v-if="mode === 'NEW'">
          Feel free to skip the optional fields and fill them later
        </template>
        <template v-if="mode === 'EDIT'">
          Some of the fields or non-editable
        </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar
        v-model="tab"
        :tabs="tabs"
        :is-loading="isLoading"
        @save="mode === 'EDIT' ? updateUser() : createUser()"
      />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)">
        <div class="row">
          <ValidationObserver ref="form">
            <div class="form">
              <!-- login details -->

              <LoginDetails
                ref="LOGIN_DETAILS"
                :mode="mode"
                :role.sync="role"
                :email.sync="email"
                :user-name.sync="userName"
                :login-type.sync="loginType"
                :password.sync="password"
                :last-name.sync="lastName"
                :first-name.sync="firstName"
                :password-age.sync="passwordAge"
                :is-active="tab === 'LOGIN_DETAILS'"
                @click="tab = 'LOGIN_DETAILS'"
              />

              <!-- ... -->

              <BaseSeparator has-left-inset />

              <!-- business details -->

              <BusinessDetails
                ref="BUSINESS_DETAILS"
                :mode="mode"
                :job-title.sync="jobTitle"
                :manager-id.sync="managerId"
                :department.sync="department"
                :is-active="tab === 'BUSINESS_DETAILS'"
                @click="tab = 'BUSINESS_DETAILS'"
              />

              <!-- ... -->

              <BaseSeparator has-left-inset />

              <!-- group details -->

              <GroupDetails
                ref="GROUP_DETAILS"
                :groups.sync="groups"
                :is-active="tab === 'GROUP_DETAILS'"
                @click="tab = 'GROUP_DETAILS'"
              />

              <!-- ... -->

              <BaseSeparator has-left-inset />

              <!-- personal details -->

              <PersonalDetails
                ref="PERSONAL_DETAILS"
                :phone-no.sync="phoneNo"
                :country-code.sync="countryCode"
                :secondary-email.sync="secondaryEmail"
                :is-active="tab === 'PERSONAL_DETAILS'"
                @click="tab = 'PERSONAL_DETAILS'"
              />

              <!-- ... -->
            </div>
          </ValidationObserver>

          <q-space />

          <!-- quick access -->

          <QuickAccess v-if="mode === 'EDIT'" @click="goto" />

          <!-- ... -->
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "@/components/common/item-builder/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import { user } from "@/api/factory.js";
import LoginDetails from "./components/LoginDetails.vue";
import BusinessDetails from "./components/BusinessDetails.vue";
import GroupDetails from "./components/GroupDetails.vue";
import PersonalDetails from "./components/PersonalDetails.vue";
import QuickAccess from "./components/QuickAccess.vue";

export default {
  name: "UserBuilder",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    LoginDetails,
    BusinessDetails,
    GroupDetails,
    PersonalDetails,
    QuickAccess,
  },

  props: {
    userId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "NEW",
      tabs: [
        {
          id: this.$nano.id(),
          label: "login details",
          value: "LOGIN_DETAILS",
        },
        {
          id: this.$nano.id(),
          label: "business details",
          value: "BUSINESS_DETAILS",
        },
        {
          id: this.$nano.id(),
          label: "group details",
          value: "GROUP_DETAILS",
        },
        {
          id: this.$nano.id(),
          label: "personal details",
          value: "PERSONAL_DETAILS",
        },
      ],
      tab: "LOGIN_DETAILS",
      isLoading: false,

      // loginDetails

      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      loginType: "Ezofis",
      password: "",
      passwordAge: 120,
      role: "",

      // groupDetails

      groups: [],

      // businessDetails

      jobTitle: "",
      department: "",
      managerId: "",

      // personalDetails

      countryCode: "",
      phoneNo: "",
      secondaryEmail: "",
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    userId: {
      immediate: true,
      handler() {
        if (this.userId) {
          this.mode = "EDIT";
          this.getUser();
        }
      },
    },
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    goto(tab) {
      this.tab = tab;
    },

    /* api functions */

    async createUser() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      if (!this.email && !this.userName) {
        this.$alert.warning("The email address or username is required");
        return;
      }

      this.isLoading = true;

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        loginName: this.userName,
        loginType: this.loginType,
        password: this.password,
        passwordAge: this.passwordAge,
        role: this.role,
        jobTitle: this.jobTitle,
        department: this.department,
        managerId: this.managerId,
        groups: this.groups,
        countryCode: this.countryCode,
        phoneNo: this.phoneNo,
        secondaryEmail: this.secondaryEmail,
      };

      const { error } = await user.createUser(payload);

      if (error) {
        if (error !== "error creating user") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;
        return;
      }

      this.$alert.success("New user created");
      this.closeBuilder();
    },

    async getUser() {
      const { error, payload } = await user.getUser(this.userId);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.email = payload.email;
      this.userName = payload.loginName;
      this.loginType = payload.loginType;
      this.passwordAge = payload.passwordAge;
      this.role = payload.role;

      this.jobTitle = payload.jobTitle;
      this.department = payload.department;
      this.managerId = payload.manager ? payload.manager.id : 0;

      this.groups = payload.groups
        ? payload.groups.map((group) => group.id)
        : [];

      this.countryCode = payload.countryCode;
      this.phoneNo = payload.phoneNo;
      this.secondaryEmail = payload.secondaryEmail;
    },

    async updateUser() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        loginName: this.userName,
        loginType: this.loginType,
        password: this.password,
        passwordAge: this.passwordAge,
        role: this.role,
        jobTitle: this.jobTitle,
        department: this.department,
        managerId: this.managerId,
        groups: this.groups,
        countryCode: this.countryCode,
        phoneNo: this.phoneNo,
        secondaryEmail: this.secondaryEmail,
      };

      const { error } = await user.updateUser(this.userId, payload);

      this.isLoading = false;

      if (error) {
        if (error !== "error updating user") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        return;
      }

      this.$alert.success("User updated successfully");
      this.closeBuilder();
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#user-builder {
  height: 100vh;
}
</style>
