<template>
  <div id="user-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template v-if="mode === 'NEW'">Create Profile</template>
        <template v-if="mode === 'EDIT'">Edit Profile</template>
      </template>

      <template #description>
        <template v-if="mode === 'NEW'">
          Feel free to skip the optional fields and fill them later
        </template>
        <template v-if="mode === 'EDIT'">
          Some of the fields or non-editable
        </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar
        v-model="tab"
        :tabs="tabs"
        :is-loading="isLoading"
        @save="mode === 'EDIT' ? updateProfile() : addProfile()"
      />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)">
        <div class="row">
          <ValidationObserver ref="form">
            <div class="form">
              <!-- profile settings -->

              <ProfileSettings
                ref="PROFILE_DETAILS"
                :mode="mode"
                :users.sync="users"
                :user-list="userList"
                :profile-name.sync="profileName"
                :description.sync="description"
                :is-active="tab === 'PROFILE_DETAILS'"
                :profile-list="profileList"
                :profile-menu="profileList"
                @click="tab = 'PROFILE_DETAILS'"
                @filterRows="filterRows"
              />
            </div>
          </ValidationObserver>

          <q-space />

          <!-- quick access -->

          <!-- <QuickAccess v-if="mode === 'EDIT'" @click="goto" /> -->

          <!-- ... -->
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "@/components/common/item-builder/ActionBar.vue";
import { ValidationObserver } from "vee-validate";
import { user, profile } from "@/api/factory.js";
import ProfileSettings from "./components/ProfileSettings.vue";
// import QuickAccess from "./components/QuickAccess.vue";

export default {
  name: "ProfileBuilder",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    ProfileSettings,
    // QuickAccess,
  },

  props: {
    profileId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "NEW",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Profile details",
          value: "PROFILE_DETAILS",
        },
      ],
      tab: "PROFILE_DETAILS",
      isLoading: false,

      userList: [],
      profileName: "",
      description: "",
      users: [],
      profile: {},
      profileList: [],
      profileMenu: [],
      profileMenuList: [],
    };
  },

  watch: {
    tab() {
      this.$refs[this.tab].$el.scrollIntoView({ behavior: "smooth" });
    },

    profileId: {
      immediate: true,
      handler() {
        if (this.profileId) {
          this.mode = "EDIT";
          this.getProfile();
        }
      },
    },
  },
  created() {
    this.getUsers();
    if (this.mode === "NEW") {
      this.getProfileList();
    }
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    goto(tab) {
      this.tab = tab;
    },

    filterRows(search) {
      if (search) {
        this.profileList = this.profileMenuList.filter((res) => {
          return JSON.stringify(res)
            .toLocaleLowerCase()
            .match(search.toLocaleLowerCase());
        });
      } else {
        this.profileList = this.profileMenuList;
      }
    },

    /* api functions */

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: String(user.id),
          });
        });
      }
    },

    async addProfile() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;
      let input = {
        name: this.profileName,
        description: this.description,
        menuOptions: this.profileList,
        userId_Array: this.users,
      };
      const { error, payload } = await profile.addProfile(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.isLoading = false;

      console.log(payload);
      if (payload) {
        this.$alert.success("profile added successfully");
      }
      this.closeBuilder();
    },

    async updateProfile() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;
      let input = {
        name: this.profileName,
        description: this.description,
        menuOptions: this.profileList,
        userId_Array: this.users,
      };
      const { error, payload } = await profile.updateProfile(
        this.profileId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.isLoading = false;

      console.log(payload);
      if (payload) {
        this.$alert.success("profile updated successfully");
      }
      this.closeBuilder();
    },

    async getProfileList() {
      const { error, payload } = await profile.getProfileList(0);

      if (error) {
        this.$alert.error(error);
        return;
      }

      // console.log(payload);

      this.profile = payload;
      this.profileList = JSON.parse(this.profile.menuOptions);
      this.profileMenuList = this.profileList;
    },

    async getProfile() {
      const { error, payload } = await profile.getProfileList(this.profileId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);

      this.profileName = payload.name;
      this.description = payload.description;
      this.users = payload.userId_Array;
      this.profileList = JSON.parse(payload.menuOptions);
      this.profileMenuList = this.profileList;
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#user-builder {
  height: 100vh;
}
</style>
