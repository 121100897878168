<template>
  <FormSection
    :icon="isActive ? 'mdi-account-multiple' : 'mdi-account-multiple-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Group Details</template>
    <template #description>
      Input essential information about the group to establish a cohesive
      profile, facilitating effective management and organization of
      group-specific details."
    </template>

    <!-- group -->

    <MultiSelectField
      label="groups"
      :value="groups"
      :options="groupList"
      @input="updateGroups"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import { group } from "@/api/factory.js";

export default {
  name: "GroupDetails",

  components: { FormSection, MultiSelectField },

  props: {
    groups: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      groupList: [],
    };
  },

  created() {
    this.getGroups();
  },

  methods: {
    updateGroups(groups) {
      this.$emit("update:groups", groups);
    },

    /* api functions */

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped></style>
