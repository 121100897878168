<template>
  <FormSection
    :icon="isActive ? 'mdi-lock' : 'mdi-lock-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Login Details</template>
    <template #description>
      Enter the desired user details and define preferred login settings to
      effortlessly generate a new user account
    </template>

    <div class="row q-mb-lg">
      <!-- first name -->

      <ValidationProvider
        v-slot="{ errors }"
        name="first name"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="firstName"
          is-mandatory
          label="first name"
          :error="errors[0]"
          @input="updateFirstName"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- last name -->

      <TextField
        :value="lastName"
        label="last name"
        class="col q-ml-md"
        @input="updateLastName"
      />

      <!-- ... -->
    </div>

    <!-- email -->

    <ValidationProvider
      v-slot="{ errors }"
      name="email"
      :rules="{ email: true }"
    >
      <TextField
        label="email"
        :value="email"
        :error="errors[0]"
        class="q-mb-lg"
        @input="updateEmail"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- username -->

    <ValidationProvider
      v-slot="{ errors }"
      name="minimum"
      class="col"
      :rules="{
        min: 3,
        alphaDash: true,
      }"
    >
      <div class="q-mb-lg">
        <TextField
          label="username"
          :value="userName"
          :error="errors[0]"
          :tooltip="_usernameHint"
          @input="updateUserName"
        />

        <div v-if="!userName" class="text-sm text-grey">
          If you don't provide a username, you can use your email address
          instead.
        </div>
      </div>
    </ValidationProvider>
    <!-- ... -->

    <!-- login type -->

    <FormFieldLabel label="Login Type" is-mandatory />

    <SingleChoiceField
      v-model="loginType"
      :options="loginTypes"
      :options-per-line="1"
      class="q-mb-lg"
      @input="updateLoginType"
    />

    <!-- ... -->
    <template v-if="loginType === 'Ezofis'">
      <!-- password -->

      <FormFieldLabel label="password" is-mandatory :tooltip="_passwordHint" />

      <div
        v-if="mode === 'EDIT'"
        class="row"
        :class="resetPassword ? 'q-mb-md' : 'q-mb-lg'"
      >
        <q-toggle v-model="resetPassword" color="secondary" dense />

        <div class="font-medium q-ml-md">Reset Password</div>
      </div>

      <!-- ... -->

      <BaseSeparator v-if="resetPassword" class="q-mb-md" />

      <!-- ... -->

      <template v-if="mode === 'NEW' || resetPassword">
        <SingleChoiceField
          v-model="passwordOption"
          :options="passwordOptions"
          :options-per-line="1"
          class="q-mb-lg"
        />

        <ValidationProvider
          v-if="passwordOption === 'CREATE'"
          v-slot="{ errors }"
          name="password"
          :rules="{ required: passwordOption === 'CREATE', password: true }"
        >
          <PasswordField
            is-mandatory
            :value="password"
            :error="errors[0]"
            class="q-mb-lg"
            @input="updatePassword"
          />
        </ValidationProvider>
      </template>

      <!-- ... -->

      <!-- password age -->

      <SingleChoiceField
        is-mandatory
        label="password age"
        tooltip="Force users to change password every n days for better security"
        :value="passwordAge"
        :options-per-line="4"
        :options="passwordAgeOptions"
        class="q-mb-lg"
        @input="updatePasswordAge"
      />

      <!-- ... -->
    </template>

    <!-- role -->

    <ValidationProvider
      v-slot="{ errors }"
      name="role"
      :rules="{ required: true }"
    >
      <SelectField
        is-mandatory
        label="role"
        :value="role"
        :options="roles"
        :error="errors[0]"
        @input="updateRole"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { lowerCase, startCase } from "lodash-es";
import Role from "@/constants/role.js";
import { passwordHint } from "@/helpers/password-hint.js";
import { usernameHint } from "@/helpers/username-hint.js";

export default {
  name: "LoginDetails",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    PasswordField,
    SingleChoiceField,
    SelectField,
    FormFieldLabel,
  },

  props: {
    firstName: {
      type: String,
      required: true,
    },

    lastName: {
      type: String,
      required: true,
    },

    email: {
      type: String,
      required: true,
    },

    userName: {
      type: String,
      required: true,
    },

    loginType: {
      type: String,
      required: true,
    },

    password: {
      type: String,
      required: true,
    },

    passwordAge: {
      type: Number,
      required: true,
    },

    role: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      loginTypes: [
        {
          id: this.$nano.id(),
          label: "Password",
          value: "Ezofis",
        },
        {
          id: this.$nano.id(),
          label: "Active Directory",
          value: "ADUser",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Google",
        //   value: "Google",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "Microsoft",
        //   value: "Microsoft",
        // },
      ],
      passwordOption: "GENERATE",
      passwordOptions: [
        {
          id: this.$nano.id(),
          label: "Automatically generate a password",
          value: "GENERATE",
        },
        {
          id: this.$nano.id(),
          label: "Create password",
          value: "CREATE",
        },
      ],
      passwordAgeOptions: [
        {
          id: this.$nano.id(),
          label: "30 Days",
          value: 30,
        },
        {
          id: this.$nano.id(),
          label: "60 Days",
          value: 60,
        },
        {
          id: this.$nano.id(),
          label: "90 Days",
          value: 90,
        },
        {
          id: this.$nano.id(),
          label: "120 Days",
          value: 120,
        },
      ],
      resetPassword: false,
    };
  },

  computed: {
    _passwordHint() {
      if (this.mode === "EDIT" && !this.resetPassword) {
        return "";
      }

      return passwordHint;
    },

    _usernameHint() {
      return usernameHint;
    },

    roles() {
      const roles = Object.keys(Role);

      return roles.map((role) => ({
        id: this.$nano.id(),
        label: startCase(lowerCase(role)),
        value: role,
      }));
    },
  },

  watch: {
    passwordOption: {
      immediate: true,
      handler() {
        if (this.passwordOption === "GENERATE") {
          this.updatePassword("Ezofis@123");
        }

        if (this.passwordOption === "CREATE") {
          this.updatePassword("");
        }
      },
    },
  },

  methods: {
    updateFirstName(firstName) {
      this.$emit("update:firstName", firstName);
    },

    updateLastName(lastName) {
      this.$emit("update:lastName", lastName);
    },

    updateEmail(email) {
      this.$emit("update:email", email);
    },

    updateUserName(userName) {
      this.$emit("update:userName", userName);
    },

    updateLoginType(loginType) {
      this.$emit("update:loginType", loginType);
    },

    updatePassword(password) {
      this.$emit("update:password", password);
    },

    updatePasswordAge(passwordAge) {
      this.$emit("update:passwordAge", passwordAge);
    },

    updateRole(role) {
      this.$emit("update:role", role);
    },
  },
};
</script>

<style lang="scss" scoped></style>
