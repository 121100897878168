var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormSection',_vm._g({attrs:{"icon":_vm.isActive ? 'mdi-lock' : 'mdi-lock-outline',"is-active":_vm.isActive},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Profile Details")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Define the necessary menus to the user when they enter login")]},proxy:true}])},_vm.$listeners),[_c('div',{staticClass:"row q-mb-lg"},[_c('ValidationProvider',{staticClass:"col",attrs:{"name":"Profile name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextField',{attrs:{"value":_vm.profileName,"is-mandatory":"","label":"Profile name","error":errors[0]},on:{"input":_vm.updateProfileName}})]}}])})],1),_c('TextAreaField',{staticClass:"q-mb-lg",attrs:{"value":_vm.description,"label":"description"},on:{"input":_vm.updateDescription}}),_c('ValidationProvider',{staticClass:"col",attrs:{"name":"Profile name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('MultiSelectField',{staticClass:"q-mb-lg",attrs:{"is-mandatory":"","label":"Users","error":errors[0],"options":_vm.userList},on:{"input":_vm.updateUsers},model:{value:(_vm.users),callback:function ($$v) {_vm.users=$$v},expression:"users"}})]}}])}),[_c('div',[_c('FormFieldLabel',{attrs:{"label":"Profile Menu","is-mandatory":""}}),_c('div',{staticClass:"q-pa-md",staticStyle:{"border":"1px solid var(--divider-color)"}},[_c('div',{staticClass:"q-mb-md",attrs:{"id":"global-search"}},[_c('div',[_c('BaseIcon',{staticClass:"icon",class:[
                {
                  'is-selected': _vm.isAllSelected,
                  'is-focused': _vm.isFocused,
                },
              ],attrs:{"name":_vm.isAllSelected
                  ? 'mdi-checkbox-marked'
                  : 'mdi-checkbox-blank-outline',"inherit-color":""},on:{"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false},"click":function($event){_vm.isAllSelected = !_vm.isAllSelected}}})],1),_c('BaseIcon',{attrs:{"name":"eva-search"}}),_c('div',{staticClass:"label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"searchQuery",staticClass:"q-px-xs",attrs:{"type":"text","placeholder":"Search in profile menu..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})])],1),_c('MultipleCheckboxField',{staticClass:"q-pb-md",attrs:{"options":_vm.profileList,"options-per-line":1},model:{value:(_vm.profileMenu),callback:function ($$v) {_vm.profileMenu=$$v},expression:"profileMenu"}})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }