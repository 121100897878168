<template>
  <div id="quick-access">
    <div class="title-3 q-mx-md q-mb-md">Quick access</div>

    <ListItem
      v-for="item in quickAccess"
      :key="item.id"
      :icon="item.icon"
      :label="item.label"
      @click="handleClick(item.value)"
    />
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "QuickAccess",

  components: { ListItem },

  data() {
    return {
      quickAccess: [
        {
          id: this.$nano.id(),
          label: "Change password",
          value: "LOGIN_DETAILS",
          icon: "eva-lock-outline",
        },
        {
          id: this.$nano.id(),
          label: "Add user to a Group",
          value: "GROUP_DETAILS",
          icon: "eva-people-outline",
        },
        {
          id: this.$nano.id(),
          label: "Change Phone No",
          value: "PERSONAL_DETAILS",
          icon: "eva-person-outline",
        },
        {
          id: this.$nano.id(),
          label: "Add Manager",
          value: "BUSINESS_DETAILS",
          icon: "mdi-briefcase-outline",
        },
        {
          id: this.$nano.id(),
          label: "Change Role",
          value: "LOGIN_DETAILS",
          icon: "eva-lock-outline",
        },
      ],
    };
  },

  methods: {
    handleClick(value) {
      this.$emit("click", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#quick-access {
  margin: 24px 0px;
  width: 240px;
  border-left: 1px solid var(--divider-color);
  border-radius: 4px;
}
</style>
