const Role = {
  ADMINISTRATOR: "ADMINISTRATOR",
  WORKSPACE_OWNER: "WORKSPACE_OWNER",
  PROCESS_OWNER: "PROCESS_OWNER",
  REPOSITORY_OWNER: "REPOSITORY_OWNER",
  BUSINESS_USER: "BUSINESS_USER",
};

Object.freeze(Role);

export default Role;
