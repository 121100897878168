<template>
  <div id="multiple-checkbox-field">
    <div
      class="options-wrapper"
      :class="{ 'is-readonly': isReadonly, 'is-disabled': isDisabled }"
    >
      <div class="row q-col-gutter-md">
        <template v-for="option in options">
          <template v-for="(value1, key) in option">
            <div
              v-if="key !== 'Menu' && key !== 'dashboard'"
              :key="key"
              :tabindex="0"
              class="option"
              :class="[
                _optionsPerLine,
                {
                  'is-selected': isSelected(value1),
                  'is-focused': isFocused === key,
                },
              ]"
              @focus="isFocused = key"
              @blur="isFocused = ''"
              @click="selectOption(option)"
              @keypress.space.stop="selectOption(option)"
            >
              <BaseIcon
                v-if="key !== 'Menu' && key !== 'dashboard'"
                :key="key"
                :name="
                  isSelected(value1)
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
                inherit-color
                class="icon"
                :class="[
                  option.description || optionsPerLine === 1
                    ? 'q-mr-md'
                    : 'q-mr-sm',
                ]"
              />
              <div>
                <!-- <div class="label">{{ option }}</div> -->
                <div v-if="key !== 'Menu'" class="label">
                  {{ key }}
                  <!-- {{ option.Menu }} -->
                </div>
              </div>
            </div>
          </template>
          <div
            v-for="(menu, key1) in option.Menu"
            :key="menu[key1]"
            :tabindex="0"
            class="option1"
            :class="[
              'col-12',
              {
                'is-selected': isSelected(menu),
                'is-focused': isFocused === key1,
              },
            ]"
            @focus="isFocused = key1"
            @blur="isFocused = ''"
            @click="selectOptionMenu(option, key1)"
            @keypress.space.stop="selectOptionMenu(option, key1)"
          >
            <BaseIcon
              :key="key1"
              :name="
                isSelected(menu)
                  ? 'mdi-checkbox-marked'
                  : 'mdi-checkbox-blank-outline'
              "
              inherit-color
              class="icon"
              :class="[
                option.description || optionsPerLine === 1
                  ? 'q-mr-md'
                  : 'q-mr-sm',
              ]"
            />
            <div>
              <div class="label">{{ key1 }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
// import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "MultipleCheckboxField",

  components: { FormFieldError },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    optionsPerLine: {
      type: Number,
      default: 3,
      validator(value) {
        return [0, 1, 2, 3, 4, 6].includes(value);
      },
    },

    allSelected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFocused: "",
    };
  },

  computed: {
    _optionsPerLine() {
      switch (this.optionsPerLine) {
        case 0:
          return "col";
        case 2:
          return "col-6";
        case 3:
          return "col-4";
        case 4:
          return "col-3";
        case 6:
          return "col-2";
        default:
          return "col-12";
      }
    },
  },

  methods: {
    isSelected(option) {
      // return !!this.value.find((_option) => _option === option.value);
      return option;
    },

    selectOption(option) {
      // console.log(option);
      for (var key in option) {
        if (key !== "Menu") {
          option[key] = !option[key];
          key = option[key];
        }
      }
    },

    selectOptionMenu(option, key1) {
      option.Menu[key1] = !option.Menu[key1];
      key1 = option[key1];
      for (let col in option) {
        if (col !== "Menu") {
          if (!option[col]) {
            option[col] = true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#multiple-checkbox-field {
  .options-wrapper {
    // border-top: 1px solid var(--divider-color);
    // padding-top: 12px;
    text-transform: capitalize;
    margin-left: 8px;
  }

  .option {
    display: flex;
    align-items: center;
    outline: none;
    margin-top: 5px;
    // border-top: 1px solid var(--divider-color);

    .label {
      color: var(--icon-color);
    }

    .icon {
      color: var(--icon-color-inverted);
      // color: var(--body-text-color);
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-top: 4px;
    }

    &:hover {
      cursor: pointer;

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }

  .option1 {
    display: flex;
    align-items: center;
    outline: none;
    margin-left: 14px;
    // border-bottom: 1px solid var(--divider-color);

    .label {
      color: var(--icon-color);
    }

    .icon {
      color: var(--icon-color-inverted);
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-top: 4px;
    }

    &:hover {
      cursor: pointer;

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }
}
</style>
