<template>
  <FormSection
    :icon="isActive ? 'mdi-account' : 'mdi-account-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Personal Details</template>
    <template #description>
      Provide user's personal information to create a comprehensive profile,
      enabling personalized experiences and tailored settings within the
      software.
    </template>

    <!-- phone -->
    <div class="row q-mb-lg">
      <!-- <SelectField
        new-option
        label="country code"
        :value="countryCode"
        :options="countryCodes"
        class="col-4"
        @input="updateCountryCode"
      /> -->
      <CountryCodeField
        :label="'Country Code'"
        :placeholder="'Country Code'"
        :code="countryCode"
        class="col"
        @input="updateCountryCode"
      />

      <TextField
        label="phone no"
        :value="phoneNo"
        class="col q-ml-md"
        @input="updatePhoneNo"
      />
    </div>

    <!-- ... -->

    <!-- email -->

    <ValidationProvider
      v-slot="{ errors }"
      name="secondary email"
      :rules="{ email: true }"
    >
      <TextField
        label="secondary email"
        :error="errors[0]"
        :value="secondaryEmail"
        class="q-mb-lg"
        @input="updateSecondaryEmail"
      />
    </ValidationProvider>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import CountryCodeField from "@/components/common/form/country-code-field/CountryCodeField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import { user } from "@/api/factory.js";

export default {
  name: "PersonalDetails",

  components: {
    ValidationProvider,
    FormSection,
    TextField,
    CountryCodeField,
  },

  props: {
    countryCode: {
      type: String,
      required: true,
    },

    phoneNo: {
      type: String,
      required: true,
    },

    secondaryEmail: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countryCodes: [],
    };
  },

  created() {
    this.getCountryCodes();
  },

  methods: {
    updateCountryCode(countryCode) {
      this.$emit("update:countryCode", countryCode);
    },

    updatePhoneNo(phoneNo) {
      this.$emit("update:phoneNo", phoneNo);
    },

    updateSecondaryEmail(secondaryEmail) {
      this.$emit("update:secondaryEmail", secondaryEmail);
    },

    /* api functions */

    async getCountryCodes() {
      const { error, payload } = await user.getUniqueColumnValues({
        column: "countryCode",
      });

      if (error) {
        this.$alert.error("Error fetching Country Codes");
        return;
      }

      this.countryCodes =
        payload &&
        payload.map((countryCode) => ({
          id: this.$nano.id(),
          label: countryCode,
          value: countryCode,
        }));
    },
  },

  /* ... */
};
</script>

<style lang="scss" scoped></style>
