<template>
  <FormSection
    :icon="isActive ? 'mdi-lock' : 'mdi-lock-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Profile Details</template>
    <template #description>
      <!-- Enter the desired menu details in user and define preferred login settings -->

      Define the necessary menus to the user when they enter login</template
    >

    <div class="row q-mb-lg">
      <!-- Profile name -->

      <ValidationProvider
        v-slot="{ errors }"
        name="Profile name"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="profileName"
          is-mandatory
          label="Profile name"
          :error="errors[0]"
          @input="updateProfileName"
        />
      </ValidationProvider>

      <!-- ... -->
    </div>

    <!-- description-->
    <TextAreaField
      :value="description"
      label="description"
      class="q-mb-lg"
      @input="updateDescription"
    />
    <!-- ... -->

    <!-- users -->
    <ValidationProvider
      v-slot="{ errors }"
      name="Profile name"
      :rules="{ required: true }"
      class="col"
    >
      <MultiSelectField
        v-model="users"
        is-mandatory
        label="Users"
        class="q-mb-lg"
        :error="errors[0]"
        :options="userList"
        @input="updateUsers"
      />
    </ValidationProvider>

    <!-- ... -->

    <template>
      <div>
        <FormFieldLabel label="Profile Menu" is-mandatory />
        <div class="q-pa-md" style="border: 1px solid var(--divider-color)">
          <div id="global-search" class="q-mb-md">
            <div>
              <BaseIcon
                :name="
                  isAllSelected
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
                inherit-color
                class="icon"
                :class="[
                  {
                    'is-selected': isAllSelected,
                    'is-focused': isFocused,
                  },
                ]"
                @focus="isFocused = true"
                @blur="isFocused = false"
                @click="isAllSelected = !isAllSelected"
              />
            </div>
            <BaseIcon name="eva-search" />

            <div class="label">
              <input
                ref="searchQuery"
                v-model="search"
                type="text"
                class="q-px-xs"
                placeholder="Search in profile menu..."
              />
            </div>
          </div>
          <MultipleCheckboxField
            v-model="profileMenu"
            :options="profileList"
            :options-per-line="1"
            class="q-pb-md"
          />
        </div>
      </div>
    </template>

    <!-- ... -->
  </FormSection>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormSection from "@/components/common/item-builder/FormSection.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { ref } from "vue";
import MultipleCheckboxField from "@/components/common/form/multiple-checkbox-field/MultipleCheckboxField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "LoginDetails",

  components: {
    FormSection,
    ValidationProvider,
    TextField,
    MultiSelectField,
    TextAreaField,
    MultipleCheckboxField,
    FormFieldLabel,
  },
  props: {
    profileName: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      default: "",
    },

    users: {
      type: Array,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    userList: {
      type: Array,
      default: () => [],
    },

    profileList: {
      type: Array,
      default: () => [],
    },

    profileMenu: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      color: ref(["Reports"]),
      search: "",
      isFocused: false,
      isAllSelected: false,
    };
  },

  computed: {},

  watch: {
    search() {
      this.$emit("filterRows", this.search);
    },

    isAllSelected() {
      if (this.profileList.length) {
        this.profileList.forEach((item) => {
          for (let menu in item) {
            // console.log(menu);
            if (menu !== "Menu" && menu !== "dashboard") {
              if (this.isAllSelected) {
                item[menu] = true;
              } else {
                item[menu] = false;
              }
            }
            if (menu === "Menu") {
              for (let subMenu in item["Menu"]) {
                // console.log(subMenu);
                if (this.isAllSelected) {
                  item.Menu[subMenu] = true;
                } else {
                  item.Menu[subMenu] = false;
                }
              }
            }
          }
        });
      }
    },
  },

  methods: {
    updateProfileName(profileName) {
      this.$emit("update:profileName", profileName);
    },

    updateDescription(description) {
      this.$emit("update:description", description);
    },

    updateUsers(users) {
      this.$emit("update:users", users);
    },
  },
};
</script>

<style lang="scss" scoped>
.control-styles {
  margin: 0 auto;
  width: 300px;
  padding-top: 25px;
}
.e-specific.e-multiselect.e-input-group .e-ddl-icon::before {
  transform: translateY(1px);
}

.e-bigger .e-specific.e-multiselect.e-input-group .e-ddl-icon::before {
  transform: translateY(2px);
}

#global-search {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  color: var(--icon-color);
  border: 1px solid var(--divider-color);

  .icon {
    color: var(--icon-color-inverted);
    border-right: 1px solid var(--divider-color);
    margin-right: 5px;
    padding: 7px;

    &.is-selected {
      color: var(--secondary) !important;
    }
  }

  .label {
    @extend .text-sm;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;

    .icon,
    .label {
      color: var(--secondary);
    }
  }

  &.is-focused {
    .icon {
      color: var(--secondary);
    }
  }
}
</style>
