var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"multiple-checkbox-field"}},[_c('div',{staticClass:"options-wrapper",class:{ 'is-readonly': _vm.isReadonly, 'is-disabled': _vm.isDisabled }},[_c('div',{staticClass:"row q-col-gutter-md"},[_vm._l((_vm.options),function(option){return [_vm._l((option),function(value1,key){return [(key !== 'Menu' && key !== 'dashboard')?_c('div',{key:key,staticClass:"option",class:[
              _vm._optionsPerLine,
              {
                'is-selected': _vm.isSelected(value1),
                'is-focused': _vm.isFocused === key,
              },
            ],attrs:{"tabindex":0},on:{"focus":function($event){_vm.isFocused = key},"blur":function($event){_vm.isFocused = ''},"click":function($event){return _vm.selectOption(option)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();return _vm.selectOption(option)}}},[(key !== 'Menu' && key !== 'dashboard')?_c('BaseIcon',{key:key,staticClass:"icon",class:[
                option.description || _vm.optionsPerLine === 1
                  ? 'q-mr-md'
                  : 'q-mr-sm',
              ],attrs:{"name":_vm.isSelected(value1)
                  ? 'mdi-checkbox-marked'
                  : 'mdi-checkbox-blank-outline',"inherit-color":""}}):_vm._e(),_c('div',[(key !== 'Menu')?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(key)+" ")]):_vm._e()])],1):_vm._e()]}),_vm._l((option.Menu),function(menu,key1){return _c('div',{key:menu[key1],staticClass:"option1",class:[
            'col-12',
            {
              'is-selected': _vm.isSelected(menu),
              'is-focused': _vm.isFocused === key1,
            },
          ],attrs:{"tabindex":0},on:{"focus":function($event){_vm.isFocused = key1},"blur":function($event){_vm.isFocused = ''},"click":function($event){return _vm.selectOptionMenu(option, key1)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();return _vm.selectOptionMenu(option, key1)}}},[_c('BaseIcon',{key:key1,staticClass:"icon",class:[
              option.description || _vm.optionsPerLine === 1
                ? 'q-mr-md'
                : 'q-mr-sm',
            ],attrs:{"name":_vm.isSelected(menu)
                ? 'mdi-checkbox-marked'
                : 'mdi-checkbox-blank-outline',"inherit-color":""}}),_c('div',[_c('div',{staticClass:"label"},[_vm._v(_vm._s(key1))])])],1)})]})],2)]),(_vm.error)?_c('FormFieldError',{attrs:{"error":_vm.error}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }